<template>
  <vs-row id="div-with-loading" class="vs-con-loading__container">
    <vs-col vs-lg="7" vs-sm="8">
      <Accordion :items="accordionItems" open>
        <template #chatbotSettings>
          <vs-input
            label="Nome para o chat"
            v-model="chatbotName"
            val-icon-pack="feather"
            val-icon-danger="icon-x"
            danger-text="O nome do bot precisa de no mínimo 3 caracteres."
            class="w-full"
            :danger="chatbotName.length < 3"
          />
          <vs-input
            type="url"
            v-model="website"
            :label="$t('WebsiteToInstall')"
            class="w-full mt-5"
          />
          <vs-input
            type="email"
            label="Email para recebimento de leads"
            v-model="email"
            class="w-full mt-5"
          />
          <div
            class="vs-component vs-con-input-label vs-input w-full vs-input-primary mt-5"
          >
            <label class="vs-input--label">{{
              $t("InformTheWhatsapps")
            }}</label>
            <div class="vs-con-input telephoneInput">
              <vue-tel-input
                v-model="whatsapp"
                class="vs-inputx vs-input--input normal"
              />
            </div>
          </div>
          <vs-select
            v-model="selectedWidgetPosition"
            :label="$t('WidgetPosition')"
            width="100%"
          >
            <vs-select-item
              v-for="(item, index) in widgetPosition"
              :key="index"
              :value="item.id"
              :text="item.label"
            />
          </vs-select>
          <div class="mt-5">
            <label class="vs-input--label">{{ $t("WidgetColor") }}</label>
            <div class="mt-2 flex items-center">
              <v-swatches
                v-model="color"
                :swatches="swatches"
                fallback-input-type="color"
                :spacing-size="10"
                :swatch-size="38"
                inline
                :swatch-style="{ marginBottom: 0 }"
              />
              <vs-input
                icon-no-border
                icon-pack="feather"
                icon="icon-hash"
                v-model="color"
                class="w-36 ml-4"
              />
            </div>
          </div>
          <div class="mt-5">
            <label class="vs-input--label w-full">{{ $t("Avatar") }}</label>
            <div class="flex flex-wrap items-center">
              <div id="avatarFileOnboarding">
                <vs-avatar size="48px" :src="chatAvatar" />
              </div>
              <div>
                <vs-button
                  icon-pack="feather"
                  icon="icon-trash"
                  radius
                  color="danger"
                  type="border"
                  @click="removeAvatar"
                />
                <avatar-cropper
                  :uploadHandler="uploadAvatar"
                  @changed="changeFile"
                  trigger="#avatarFileOnboarding"
                  mimes="image/png, image/jpeg"
                  :labels="label"
                />
              </div>
            </div>
          </div>
        </template>
      </Accordion>
      <vs-button
        class="mt-5 vs-con-loading__container float-right"
        icon-pack="feather"
        icon="icon-arrow-right"
        icon-after
        :disabled="!canSend"
        @click="handleChatbot"
        id="loadableButton"
      >
        {{ $t("Next") }}
      </vs-button>
    </vs-col>
    <vs-col
      vs-lg="5"
      vs-sm="4"
      vs-type="flex"
      vs-justify="center"
      style="min-height: 670px"
    >
      <ChatAIPreview :chatbot="currentChatbot" v-if="currentChatbot" />
    </vs-col>
  </vs-row>
</template>
<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import AvatarCropper from "vue-avatar-cropper";
import ChatAIPreview from "@/components/duotalk-ia-settings/ChatAIPreview.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "ChatbotAIManagement",
  components: {
    VSwatches,
    AvatarCropper,
    ChatAIPreview,
    Accordion,
  },
  data() {
    return {
      currentChatbot: {},
      chatbotName: "",
      selectedWidgetPosition: 1,
      widgetPosition: [
        { id: 1, label: "Canto inferior direito" },
        { id: 2, label: "Canto inferior esquerdo" },
      ],
      website: "",
      email: "",
      whatsapp: "",
      color: "#002f8e",
      swatches: [
        "#002f8e",
        "#008e46",
        "#8e0000",
        "#34495e",
        "#6e008e",
        "#c68700",
      ],
      label: {
        submit: this.$t("Crop"),
        cancel: this.$t("Cancel"),
      },
      chatAvatar:
        "https://cdn-bucket-duotalk.s3.amazonaws.com/img/avatar/2021_5/60ad410fcf2443eb152b243a.png",
      DefaultAvatar:
        "https://cdn-bucket-duotalk.s3.amazonaws.com/img/avatar/robo-avatar-duotalk.png",
      chatAvatarFile: "",
      chatbotId: "",
      accordionItems: [
        { id: "chatbotSettings", title: "Configurações do chatbot" },
      ],
    };
  },
  async mounted() {
    if (this.$route.params.chatbot_id) {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });

      await this.getChatBotData(this.$route.params.chatbot_id);
    }
  },
  computed: {
    widgetURL() {
      return `${process.env.VUE_APP_WIDGET_AI_PLUGIN_URL}/index.html?acc=${
        this.$store.state.acc.current_acc.id || "preview"
      }&chat=${this.$route.params.chatbot_id || "preview"}&resetChat=true`;
    },
    canSend() {
      return !(this.chatbotName.length < 3);
    },
  },
  methods: {
    async getChatBotData(id) {
      if (!id) return null;

      const response = await this.$http.get(
        "/p/chat/" + this.$route.params.chatbot_id
      );

      if (response.status === 200) {
        this.currentChatbot = response.data.data;
        this.chatbotId = this.$route.params.chatbot_id;
        this.chatbotName = response.data.data.name;
        if (response.data.data.website)
          this.website = response.data.data.website;
        this.email = response.data.data.email
          ? response.data.data.email.join(",")
          : "";
        if (response.data.data.whatsapp)
          this.whatsapp = response.data.data.whatsapp[0];
        this.color = response.data.data.color;
        this.chatAvatar = response.data.data.chatAvatar;
        if (response.data.data.widgetPosition)
          this.selectedWidgetPosition = response.data.data.widgetPosition;
      } else {
        this.$vs.notify({
          title: this.$t("UnexpectedError"),
          text: "Chatbot não encontrado.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          duration: 10000,
          position: "top-right",
          time: 4000,
        });
        await this.$router.push({ name: "chatbots" });
      }

      this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },
    postMessageToIframe() {
      const iframe = document.getElementById("chatbot-ai");
      if (iframe) {
        let message = {};
        if (this.chatbotName != null) message.title = this.chatbotName;
        if (this.chatAvatar != null) message.img = this.chatAvatar;
        if (this.color != null) message.color = this.color;

        if (iframe.contentWindow)
          iframe.contentWindow.postMessage(message, "*");
      }
    },
    changeFile(file) {
      if (file)
        document.querySelector(".avatar-cropper").style.display = "flex";
    },
    removeAvatar() {
      this.chatAvatar = this.DefaultAvatar;
    },
    resizeBase64Img(base64, callback) {
      let img = document.createElement("img");
      img.src = base64;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        const MAX_WIDTH = 168;
        const MAX_HEIGHT = Math.ceil((height / width) * MAX_WIDTH);

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        callback(canvas.toDataURL());
      };
    },
    uploadAvatar(photo) {
      let self = this;
      if (photo) {
        const url = photo.getCroppedCanvas().toDataURL();
        this.resizeBase64Img(url, function (url) {
          fetch(url).then((resBlob) => {
            resBlob.blob().then((blob) => {
              self.chatAvatarFile = blob;
              self.chatAvatar = url;
            });
          });
        });
      }
    },
    handleChatbot() {
      this.$vs.loading({
        container: "#loadableButton",
        scale: 0.6,
      });

      const isUrl =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

      let api_uri = "/p/chat/assistant/create";
      let data = {
        name: this.chatbotName,
        widgetPosition: this.selectedWidgetPosition,
        website: this.website ? this.website : "www.example.com",
        email: this.email != null && this.email.length > 3 ? this.email : null,
        whatsapp: this.whatsapp,
        color: this.color,
        chatAvatar: isUrl.test(this.chatAvatar)
          ? this.chatAvatar
          : this.DefaultAvatar,
        acc: this.$store.state.acc.current_acc.id,
      };

      let method = "post";
      if (this.chatbotId) {
        api_uri = `/p/chat/chatbot-edit/${this.chatbotId}`;
        method = "put";
      }
      this.$http({
        method: method,
        url: api_uri,
        data: data,
      })
        .then((response) => {
          this.chatbotId = response.data.data._id;

          if (
            this.chatAvatar !== this.DefaultAvatar &&
            this.chatAvatarFile.type !== null
          ) {
            let fd = new FormData();
            fd.append("content", this.chatAvatar);
            fd.append("mime", this.chatAvatarFile.type);
            this.$http
              .post("/p/chat/avatar/" + response.data.data._id, fd, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.auth.accessToken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .catch((error) => {
                this.$vs.notify({
                  title: this.$t("UnexpectedError"),
                  text: error,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                  duration: 10000,
                  position: "top-right",
                  time: 4000,
                });
              });
          }

          this.$router.push({
            name: this.$route.name.includes('hybrid') 
                ? 'chatbot-hybrid-edit' 
                : 'chatbot-ai-edit',
            params: {
              chatbot_id: this.chatbotId,
            },
          });

          this.$vs.loading.close("#loadableButton > .con-vs-loading");
        })
        .catch((error) => {
          if (error && error.request != null)
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  watch: {
    chatbotName() {
      this.postMessageToIframe();
    },
    color() {
      this.postMessageToIframe();
    },
    chatAvatar() {
      this.postMessageToIframe();
    },
  },
};
</script>
<style lang="scss">
.telephoneInput .vs-input--input.normal {
  padding: 0.2rem !important;
}
.iframe-container {
  width: 450px;
  height: 606px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
}

.vs-collapse {
  padding: 0 !important;
}
.con-content--item,
.vs-collapse-item--header {
  background: #fff !important;
}
</style>
