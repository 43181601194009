<template>
  <div class="">
    <div class="flex justify-center w-full mb-5">
      <vs-button
        type="border"
        icon-pack="feather"
        icon="icon-refresh-cw"
        icon-after
        @click="clear()"
      >
        {{ $t("TestChatbot") }}
      </vs-button>
    </div>
    <div class="overflow-hidden iframe-container" :class="{ hidden: !ready }">
      <iframe
        id="chatbot-ai"
        :key="iframeKey"
        :src="widgetURL"
        width="100%"
        height="100%"
        class="border-none"
      />
    </div>
  </div>
</template>
<script>
import socket from "@/views/apps/chatbots/SocketAI";
export default {
  name: "ChatAIPreview",
  props: {
    chatbot: {
      type: Object,
    },
  },
  data() {
    return {
      iframeKey: 0,
      message: "",
      typing: false,
      ready: false,
      messageEventListener: null,
    };
  },
  computed: {
    widgetURL() {
      return `${process.env.VUE_APP_WIDGET_AI_PLUGIN_URL}/index.html?acc=preview&chat=preview&resetChat=true&isChatOpen=true`;
    },
    isHybrid() {
      return this.chatbot.type === "hybrid";
    },
  },
  beforeDestroy() {
    window.removeEventListener("message", this.messageEventListener);
    socket.disconnect();
  },
  mounted() {
    this.initializeSocket();
    this.setupMessageListener();
  },
  methods: {
    async initializeSocket() {
      if (!this.chatbot || !this.chatbot._id) {
        return;
      }

      socket.auth = {
        chatID: this.chatbot._id,
        type: this.chatbot.type || "normal",
        token: this.$store.state.auth.accessToken,
      };

      await socket.connect();

      this.serveWidget();
    },
    setupMessageListener() {
      const thisIns = this;
      this.messageEventListener = (event) => {
        if (event.data === "ready") thisIns.serveWidget();
        if (event.data && event.data.new_user_message)
          thisIns.sendMessage(event.data.new_user_message);
      };

      window.addEventListener("message", this.messageEventListener);
    },
    async serveWidget() {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const iframe = document.getElementById("chatbot-ai");
      if (iframe != null) {
        this.ready = true;
        let message = {};
        if (this.chatbot.name != null) message.title = this.chatbot.name;
        if (this.chatbot.chatAvatar != null)
          message.img = this.chatbot.chatAvatar;
        if (this.chatbot.color != null) message.color = this.chatbot.color;
        message.isHybrid = this.isHybrid;
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage(message, "*");
        }

        socket.on("messages", (messages) => {
          iframe.contentWindow.postMessage({ messages }, "*");
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));
        socket.emit("get messages");
      }
    },
    sendMessage(msg) {
      const iframe = document.querySelector("#chatbot-ai");
      this.isHybrid
        ? this.handleHybridFlow(msg, iframe)
        : this.handleAiFlow(msg, iframe);
    },

    handleHybridFlow(msg, iframe) {
      socket.emit("flow message", msg, (message, isAssistant) => {
        const messagePayload = {
          new_message: {
            ...message,
            text: message.content,
            author: isAssistant ? "assistant" : "flow",
          },
        };

        iframe.contentWindow.postMessage(messagePayload, "*");
      });
    },
    handleAiFlow(msg, iframe) {
      socket.emit("private message", msg, (message) => {
        const messagePayload = {
          new_message: {
            author: "assistant",
            text: message,
          },
        };

        iframe.contentWindow.postMessage(messagePayload, "*");
      });
    },
    clear() {
      socket.removeAllListeners();
      socket.emit("clear");
      this.iframeKey++;
      this.serveWidget();
    },
  },
  watch: {
    "chatbot._id": {
      handler(newVal) {
        if (newVal) {
          this.initializeSocket();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.iframe-container {
  width: 450px;
  height: 606px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
}
</style>
